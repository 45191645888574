import AnswerComp from "./AnswerComp";
import "./QuestionSection.css";

const QuestionSection = ({ question, onSelectedAnswersChange, currentSelectedAnswers }) => {

  const textAnswers = question.answers.filter(answer => !answer.image);
  const imageAnswers = question.answers.filter(answer => answer.image);

  const handleAnswerToggle = (id, isChecked) => {
    const newSelectedAnswers = { ...currentSelectedAnswers, [id]: isChecked };
    onSelectedAnswersChange(newSelectedAnswers);
  };

  // call resetAnswers whenever the component is re-rendered
  return (
    <div className="fragen-section1">
      <div className="fragen-text">
        <b className="wen-muss-ich">
          {question.frage}
        </b>
      </div>
      {question.image && (
        <img className="question-image" src={question.image} alt={question.frage} width={300} />
      )}
      <div style={{ width: "100%", display: "flex", flexDirection: "column", alignSelf: "center", gap: "10px" }}>
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}> {/* flexWrap nur hier */}
          {imageAnswers.map((answer) => (
            <div style={{ display: 'flex', padding: 5, flexDirection: 'column' }} key={answer.id}>
              <AnswerComp
                text={answer.text}
                id={answer.id}
                image={answer.image}
                onAnswerToggle={handleAnswerToggle}
                isInitiallyChecked={currentSelectedAnswers[answer.id] || false}
              />
            </div>
          ))}
        </div>
        {textAnswers.map((answer) => (
          <AnswerComp
            key={answer.id}
            text={answer.text}
            id={answer.id}
            onAnswerToggle={handleAnswerToggle}
            isInitiallyChecked={currentSelectedAnswers[answer.id] || false}
          />
        ))}
      </div>

    </div>
  );
};

export default QuestionSection;
