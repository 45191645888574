import React, { useMemo, useCallback, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import './editor.css';
import Quill from 'quill';
window.Quill = Quill;
const ImageResize = require('quill-image-resize-module').default;
const Font = Quill.import("formats/font");
Font.whitelist = ['Arial', 'Verdana', 'Calibri']; // Add 'Calibri' here
Quill.register(Font, true);
Quill.register('modules/imageResize', ImageResize);
let BlockEmbed = Quill.import('blots/block/embed');

class VideoBlot extends BlockEmbed {
    static create(value) {
        let node = super.create(value);
        node.setAttribute('frameborder', '0');
        node.setAttribute('allowfullscreen', true);
        node.setAttribute('src', value);
        node.style.width = '50vw';   // Standardbreite
        node.style.height = '40vh'; // Standardhöhe
        return node;
    }

    static value(node) {
        return node.getAttribute('src');
    }
}
VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';

Quill.register(VideoBlot);


const Editor = ({ value, onChange, customModules, isPlainText = false }) => {

    const [t] = useTranslation('common');
    const quillRef = useRef(null);
    const imageHandler = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = (event) => {
            const file = event.target.files[0];

            if (/^image\//.test(file.type)) {
                saveToServer(file);
            } else {
                console.warn("You could only upload images.");
            }
        };
    }, []);

    const handleCustomLink = useCallback(() => {
        let quill = quillRef.current.getEditor();
        let range = quill.getSelection();
        if (range) {
            let value = "<LINK>"; // Setze den Link-Wert
            quill.format('link', value); // Füge den Link im Editor ein
        }
    }, []);



    const getPlainText = () => {
        if (quillRef.current) {
            // Dies ruft die Quill-Instanz ab.
            const quillInstance = quillRef.current.getEditor();
            // Dies extrahiert den reinen Text aus dem Editor.
            const plainText = quillInstance.getText();
            return plainText;
        }
        return '';
    }



    const videoHandler = useCallback(() => {
        let quill = quillRef.current.getEditor();
        let range = quill.getSelection();
        let value = prompt(t("admin.akd.akd-ed-infoVideoLink"));

        if (value) {
            // Überprüfen, ob es sich um einen YouTube-Link handelt und in das Einbettungsformat konvertieren
            if (value.includes("youtube.com/watch?v=")) {
                value = value.replace("youtube.com/watch?v=", "youtube.com/embed/");
                value = value + "?rel=0"; // Deaktivieren Sie die vorgeschlagenen Videos am Ende des Videos 
            }
            // Fügen Sie das Video in den Quill-Editor ein
            quill.insertEmbed(range.index, 'video', value, 'user');
        }
    }, []);



    function saveToServer(file) {
        const fd = new FormData();
        fd.append("upload", file);
        const xhr = new XMLHttpRequest();
        xhr.open("POST", process.env.REACT_APP_FILE_API_URL, true);
        //xhr.open("POST", "http://localhost:8080/api/files", true); 
        xhr.onload = () => {
            if (xhr.status === 201) {
                const url = JSON.parse(xhr.responseText).url;

                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                quill.insertEmbed(range.index, 'image', url, 'user');
            }
        };
        xhr.send(fd);
    }

    const defaultModules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ font: ['Arial', 'Verdana', 'Calibri'] }], // Include 'Calibri' here
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                    ["clean"],
                    ["link", "image", "video", { 'customLink': handleCustomLink }], // Füge deinen Button hier hinzu
                ],
                handlers: {
                    image: imageHandler,
                    video: videoHandler,
                    customLink: handleCustomLink // Definiere den Handler für dein custom Link
                },
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize', 'Toolbar'],
            },
        };
    }, [imageHandler, videoHandler, handleCustomLink]);
    

    const modulesToUse = customModules || defaultModules;


    const handleChange = (content, delta, source, editor) => {
        if (isPlainText) {
            // When isPlainText is true, we want to ensure only plain text is passed
            const plainText = content.replace(/<\/?[^>]+(>|$)/g, "").trim();

            console.log(plainText);
            onChange(plainText.trim()); // Pass plain text to the parent component's onChange handler
        } else {
            // When isPlainText is false, pass the HTML content as usual
            onChange(content); // Pass rich text (HTML content) to the parent component's onChange handler
        }
    };

    if (isPlainText) {
        return (
            <div className='ami-editor'>
                <ReactQuill
                    ref={quillRef}
                    value={value}
                    theme='snow'
                    modules={modulesToUse}
                    onChange={handleChange} // Verwenden Sie hier handleChange statt onChange.
                />
            </div>
        )
    }


    return (
        <div className='ami-editor'>
            <ReactQuill
                ref={quillRef}
                theme='snow'
                value={value}
                modules={modulesToUse}
                onChange={handleChange} // Verwenden Sie hier handleChange statt onChange.
            />
        </div>
    );
}

export default Editor;
