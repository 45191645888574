
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import "./Settings.css";
import LanguageSelector from "../components/LanguageSelector";
import { useTranslation } from "react-i18next";

const Settings = () => {

  const [t] = useTranslation('common');

  return (
    <div className="set-settings">
      <div className="set-inner-window">
        <div className="set-othermodules-btn">
          <div className="set-teilnehmen">
            <div>{t("settings.settings-Sprache")}</div>
          </div>
          <LanguageSelector />

        </div>
      </div>
      <Header />
      <Sidebar />
    </div>
  );
};

export default Settings;
