import "./QuestionSteps.css";
const QuestionSteps = ({ totalSteps, activeStep }) => {
  // Verwende ein Array mit `totalSteps` Länge
  const steps = new Array(totalSteps).fill(null);
  
  return (
    <div className="fragen-box-list">
      {steps.map((_, index) => (
        <div
          key={index}
          className={`fragen-box ${activeStep === index + 1 ? 'active' : ''}`}
        >
          <b className="b">{index + 1}</b>
        </div>
      ))}
    </div>
  );
};

export default QuestionSteps;
