import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCaretDown } from "react-icons/ai";
import { useLoading } from "../../../components/LoadingSpinner/LoadingContext";
import { useGetUsersWithRolesQuery } from "../../../features/roles/adminRoleSlice";
import UserRoleTableComponent from "../rollenverwaltung/UserRoleTableComponent";
import { GrCircleInformation } from "react-icons/gr";
import PhishingTableComponent from "./PhishingTableComponent";
import { useGetPhishingAttemptsQuery, useGetPhishingSettingsQuery } from "../../../features/phishing/adminPhishingSlice";

// const data = [
//     {
//         id: 1,
//         name: "Max Mustermann",
//         lastPhishing: "2024-04-26", // Corrected date format
//         statistic: "4/4",
//         status: "pending",
//     },
//     {
//         id: 2,
//         name: "Hans Huber",
//         lastPhishing: "2024-04-26", // Corrected date format
//         statistic: "4/4",
//         status: "failed",
//     },
//     {
//         id: 3,
//         name: "Franz Bauer",
//         lastPhishing: "2024-04-26", // Corrected date format
//         statistic: "4/4",
//         status: "passed",
//     },
// ]
const PhishingStatusTable = () => {
    const [fahrerFilter, setFahrerFilter] = useState("");

    const [t, i18n] = useTranslation("common");

    const { data, error, isLoading, isSuccess, isError } = useGetPhishingAttemptsQuery()
    const { startLoading, stopLoading } = useLoading();


    const [selectedStatus, setSelectedStatus] = useState(""); // Changed from "ROLE_SUPER_ADMIN" to ""

    const [filteredUsers, setFilteredUsers] = useState([]);


    useEffect(() => {
        if (isSuccess && data) { // Ensure data is not undefined
            console.log("data", data);
            setFilteredUsers(data || []); // Fallback to empty array if data is falsy
        }
    }, [isSuccess, data]);

    const applyFilter = () => {
        const result = mutableData.filter(user => {
            const nameMatch = fahrerFilter ? user.employee.name.toLowerCase().includes(fahrerFilter.toLowerCase()) : true;
            const statusMatch = selectedStatus ? user.status === selectedStatus : true;
            return nameMatch && statusMatch;
        });
        setFilteredUsers(result);
    };


    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            stopLoading();
        }
    }, [isLoading, startLoading, stopLoading]);

    const mutableData = isSuccess ? [...data] : [];
    // Get the filtered list of users based on the filters
    const resetFilter = () => {
        setFahrerFilter("");
        setSelectedStatus("");
        setFilteredUsers(data);
    };



    return (
        <>

            <div className="rvw-upper-section" style={{ marginTop: "50px", gap: 50, alignItems: "center" }}>
                <h3 >{t("admin.pages.pages-akd-pishing-title")}</h3>

                <div className="rvw-filter-container">
                    <div style={{ flex: 1, display: "flex", flexDirection: "row", gap: 30, }}>
                        <AutocompleteInput
                            suggestions={mutableData.map(user => user.employee.name)}
                            placeholder={t("admin.rollenverwaltung.rollenverwaltung-filter-mitarbeiter")}
                            value={fahrerFilter}
                            onChange={e => setFahrerFilter(e.target.value)}
                            isFirst={true}
                        />
                    </div>
                    <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", alignItems: "center", height: 30, position: "relative" }}>

                            <label style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>
                                <input
                                    type="checkbox"
                                    name="roleOption"
                                    value="phished"
                                    checked={selectedStatus === 'phished'}
                                    style={{ transform: "scale(1.5)", marginRight: 10 }} // Scales the checkbox to be 1.5 times the original size
                                    onChange={e => setSelectedStatus(e.target.value)}
                                />
                                {t("admin.pages.pages-akd-pishing-checkbox-phished")}
                            </label>
                        </div>

                        <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", alignItems: "center", height: 30, position: "relative" }}>
                            <label style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>
                                <input
                                    type="checkbox"
                                    name="roleOption"
                                    value="passed"
                                    checked={selectedStatus === 'passed'}
                                    onChange={e => setSelectedStatus(e.target.value)}
                                    style={{ transform: "scale(1.5)", marginRight: 10 }} // Scales the checkbox to be 1.5 times the original size
                                />
                                {t("admin.pages.pages-akd-pishing-checkbox-notphished")}
                            </label>

                        </div>
                        <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", alignItems: "center", height: 30, position: "relative" }}>
                            <label style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>
                                <input
                                    type="checkbox"
                                    name="roleOption"
                                    value="pending"
                                    checked={selectedStatus === 'pending'}
                                    onChange={e => setSelectedStatus(e.target.value)}
                                    style={{ transform: "scale(1.5)", marginRight: 10 }} // Scales the checkbox to be 1.5 times the original size
                                />
                                {t("admin.pages.pages-akd-pishing-checkbox-waiting")}
                            </label>

                        </div>

                    </div>
                    {/* <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", alignItems: "center", height: 30, position: "relative" }}>
                            <label style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>
                                <input
                                    type="checkbox"
                                    name="roleOption"
                                    value="Option2"
                                    checked={selectedStatus === 'Option2'}
                                    onChange={e => setSelectedStatus(e.target.value)}
                                    style={{ transform: "scale(1.5)", marginRight: 10 }} // Scales the checkbox to be 1.5 times the original size
                                />
                                Nicht gephished
                            </label>

                        </div>
                    </div> */}
                    <div style={{ flex: 1, display: "flex", alignItems: "end", flexDirection: "column", gap: 20, color: "white", }}>
                        <div className="ape-filter-btn ape-common-btn" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 180, height: 30, cursor: "pointer", fontFamily: "Verdana" }} onClick={applyFilter}>
                            <div className="ape-fahrer">{t("admin.pages.pages-ape-Filtern")}</div>
                        </div>
                        <div className="ape-filter-reset-btn ape-common-btn" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 180, height: 30, cursor: "pointer", fontFamily: "Verdana" }} onClick={resetFilter}>
                            <div className="ape-fahrer">{t("admin.pages.pages-ape-FilterZuruecksetzen")}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rvw-section-header">
                <div style={{ display: "flex", flex: 1, padding: 10 }}>
                    <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black", fontFamily: "Verdana" }}>
                        {t("admin.rollenverwaltung.rollenverwaltung-mitarbeitername")}
                    </div>
                    <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black", fontFamily: "Verdana" }}>
                        {t("admin.pages.pages-akd-pishing-title-table-lastAttempt")}
                    </div>
                    <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black", fontFamily: "Verdana" }}>
                        {t("admin.pages.pages-akd-pishing-title-table-statistic")}
                    </div>
                    <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", fontFamily: "Verdana" }}>
                        {t("admin.rollenverwaltung.rollenverwaltung-Status")}
                        <div>
                            <GrCircleInformation className="icon" style={{ marginLeft: 5, color: 'black', fontSize: '24px', cursor: "pointer" }} />
                            <div className="tooltip" style={{width: "120px"}}>Ausstehend: Solange der Mitarbeiter in den ersten 5 Tagen nach Erhalt der Phishingmail nicht darauf reagiert hat. <br /><br></br>
                                Gephished: Wenn der Mitarbeiter auf die Phishingmail reagiert hat. <br /><br></br>
                                Bestanden: Wenn der Mitarbeiter die Phishingmail ignoriert hat.
                            </div>
                        </div>

                    </div>
                </div>
            </div >
            <div className="rvw-section" style={{ overflowY: "auto" }}>
                {isSuccess && [...filteredUsers]
                    .sort((a, b) => a.employee.name.localeCompare(b.employee.name)) // Sorting in ascending order by `username`
                    .map((user, index) => (
                        <PhishingTableComponent user={user} key={user.id} index={index} />
                    ))}
            </div>


        </>
    )
}




const AutocompleteInput = ({ suggestions, placeholder, value, onChange, isFirst }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState("");

    const onInputChange = e => {
        const userInput = e.target.value;

        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setFilteredSuggestions(filteredSuggestions);
        setActiveSuggestion(0);
        setShowSuggestions(true);
        setUserInput(e.target.value);
        onChange(e);

    };

    const onSuggestionClick = suggestion => {
        setFilteredSuggestions([]);
        setActiveSuggestion(0);
        setShowSuggestions(false);
        setUserInput(suggestion);
        onChange({ target: { value: suggestion } });
    };

    return (
        <div className="ape-input-wrapper" style={{ flex: 1, display: "flex", alignItems: "center", height: 30 }}>
            <input
                className="ape-fahrer-input"
                style={{ flex: 1, alignSelf: isFirst ? "start" : "center", width: "70%" }}
                type="text"
                onChange={onInputChange}
                value={value}
                placeholder={placeholder}
            />
            {showSuggestions && userInput && (
                <div className="ape-suggestions-container" style={{ border: "none" }}>
                    {filteredSuggestions.map((suggestion, index) => {
                        return (
                            <div
                                key={index}
                                className="ape-suggestion-item"
                                style={{ width: "75%" }}
                                onClick={() => {
                                    onSuggestionClick(suggestion);
                                }}
                            >
                                {suggestion}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};


export default PhishingStatusTable;
